import { init, RematchRootState, RematchDispatch } from '@rematch/core';
import { useDispatch } from 'react-redux';

import AsyncStorage from '@react-native-async-storage/async-storage';
import persist from '@rematch/persist';

import { models, RootModel } from './models';
import ExpoFileSystemStorage from 'redux-persist-expo-filesystem';
import { IS_ANDROID, IS_IOS } from 'helper';
import localforage from 'localforage';

export const store = init<RootModel>({
	models,
	plugins: [
		persist({
			key: 'hspx-dev-1.0.0-new',
			storage: IS_ANDROID ? ExpoFileSystemStorage : IS_IOS ? AsyncStorage : localforage,
			blacklist: ['socket', 'networking', 'temp'],
			version: 1
		})
	]
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type IRootState = RematchRootState<RootModel>;

export const useRematchDispatch = <D extends {}, MD>(selector: (dispatch: D) => MD) => {
	const dispatch = useDispatch<D>();
	return selector(dispatch);
};
