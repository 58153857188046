import { ILauncherSpace } from './interfaces';

export const ENVIRONMENT: 'dev' | 'stage' | 'prod' | 'bits' | 'k5' | 'nwx' | 'metro' = 'dev';
export const DEFAULT_MULTISPACE_URL = 'https://multispace-dev.hspx.de';
export const DEFAULT_PLATFORM_URL = 'https://app-dev.hellospaces.de/';
export const DEFAULT_PLATFORM_SHARE_URL = 'https://app-dev.hellospaces.de/app/spacesummary/';
export const PRESET_SPACEIDS: string[] | undefined = undefined;
export const PRESET_SPACE_FALLBACK: ILauncherSpace | undefined = undefined;
export const SENTRY_DSN = 'https://427123074d904c46baa6c9023a909c26@sentry.hspx.de/5';
export const IOS_APPSTORE_URL = 'https://apps.apple.com/de/app/hellospaces/id1615726233'
export const GOOGLE_PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=de.hellospaces.app'
export const MIN_BUILD_VERSION_IOS = 9;
export const MIN_BUILD_VERSION_ANDROID = 15;
export const APP_STORE_URL_IOS = undefined;
export const APP_STORE_URL_ANDROID = undefined;
export const DEEPLINK_SHORT = 'hspxdev://';
export const APP_LANGUAGE: 'de' | 'en' | undefined = undefined;

export const QuotaLimits = {
	attendees: 50,
	admins: 1,
	moderators: 1
};
